import { Injectable } from '@common/di';
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';
import { ConfigService } from '../ConfigService';
import { AuthService } from '../AuthService';
import { CacheService } from '../CacheService';
import { MakeObservable, observable } from '@app/common/state';
import { notifyError } from '@app/ui-kit/ToastNotifications';

interface CacheConfig {
  useCache?: boolean;
  ttl?: number;
  cacheTag?: string;
  tags?: string[];
  key?: string; // Optional: Provide explicit cache key
}

@Injectable()
@MakeObservable
export class ApiService {
  public axiosInstance: AxiosInstance;

  @observable
  public maintainSession: number | null = null;

  constructor(
    private readonly configService: ConfigService,
    private readonly authService: AuthService,
    private readonly cacheService: CacheService,
  ) {
    this.axiosInstance = axios.create({
      baseURL: cacheService.get('apiUrl') || this.configService.apiUrl,
      timeout: this.configService.apiTimeout,
    });

    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const accessToken = this.authService.getCredentials();

        if (accessToken) {
          config.headers['X-Api-Key'] =
            '9m60AhO1I9JmrYIsWxMnThXbF3nDW4GHFA1rde5PKzJmRA9Dv6LZ2YXSM6vvwigC';
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (!isAxiosError(error)) {
          return Promise.reject(error);
        }

        if (error.response?.status === 503) {
          this.maintainSession = parseInt(
            error.response.data.message.split(':')[1],
          );
          console.log(this.maintainSession);
        }

        if (
          error?.response?.headers['X-Request-Id'] &&
          error.response?.status !== 401 &&
          error.response?.status !== 403 &&
          error.response?.status !== 503
        ) {
          const errorResponse = error?.response;
          const requestId = errorResponse?.headers['X-Request-Id'] || 'N/A';
          const status = errorResponse?.status;

          notifyError(`Status:${status} ,Request ID: ${requestId}`);
        }

        return Promise.reject(error);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateCacheKey(url: string, params?: any): string {
    const paramsPart = params ? JSON.stringify(params) : '';

    return `cache_${encodeURIComponent(url)}_${encodeURIComponent(paramsPart)}`;
  }

  public async get<R, P = unknown>(
    url: string,
    params?: P,
    cacheConfig?: CacheConfig,
  ): Promise<R> {
    let cacheKey = cacheConfig?.key;

    if (cacheConfig?.useCache && !cacheKey) {
      cacheKey = this.generateCacheKey(url, params);
    }

    if (cacheKey && cacheConfig?.useCache) {
      const cachedData = this.cacheService.get<R>(cacheKey);

      if (cachedData) return cachedData;
    }

    const { data } = await this.axiosInstance.get<R>(url, { params });

    if (cacheKey && cacheConfig?.useCache) {
      this.cacheService.set(cacheKey, data, cacheConfig.ttl, cacheConfig.tags);
    }

    return data;
  }

  public async post<R, B = unknown>(
    url: string,
    body?: B,
    cacheConfig?: CacheConfig,
  ): Promise<R> {
    const { data } = await this.axiosInstance.post<R>(url, body);

    // Invalidate cache based on tags if specified after a successful POST request
    if (cacheConfig?.tags) {
      cacheConfig.tags.forEach((tag) =>
        this.cacheService.invalidateTag(
          tag,
          cacheConfig.cacheTag === tag ? data : undefined,
        ),
      );
    }

    return data;
  }
}
