import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { Button } from '../Button';
import { ReactComponent as CloseIcon } from '@icons/close.svg';

import styles from './Dialog.module.scss';

interface DialogProps {
  className?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
}

export const Dialog: React.FC<DialogProps> = ({
  className,
  children,
  open,
  onClose,
  onClick,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [open]);

  const classNames = clsx(styles.root, className, {
    [styles.open]: open,
  });

  return createPortal(
    <div className={classNames}>
      <div className={styles.cover}></div>
      <div className={styles.content}>
        <button className={styles.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
        <p className={styles.title}>{children}</p>
        <div className={styles.btnGroup}>
          <Button className={styles.btn} variant="tertiary" onClick={onClick}>
            Yes
          </Button>
          <Button className={styles.btn} variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>,
    document.body,
  );
};
