import { useEffect } from 'react';
import { animate, m, useMotionValue, useTransform } from 'framer-motion';
import clsx from 'clsx';

import { numberWithCommas } from '@app/utils';

import peachIcon from '@media/peach.webp';
import jamIcon from '@media/jam.png';
import tomatoIcon from '@media/tomato_coin.png';

import s from './CoinsIndicator.module.scss';

interface CoinsIndicatorProps {
  className?: string;
  variant?: 'peach' | 'jam' | 'tomato';
  value: number;
}

export const CoinsIndicator: React.FC<CoinsIndicatorProps> = ({
  className,
  variant = 'peach',
  value,
}) => {
  const animatedTries = useMotionValue(0);
  const roundedAnimatedTries = useTransform(
    animatedTries,
    (latest) => `${numberWithCommas(Math.round(latest))}`,
  );

  useEffect(() => {
    const controls = animate(animatedTries, value, {
      duration: 0.5,
    });

    return controls.stop;
  }, [value, animatedTries]);

  const calculateDynamicWidth = (text: string) => {
    return text.split('').reduce((acc, char) => {
      return acc + (char === ',' ? 14 : char.match(/[0-9]/) ? 28 : 0);
    }, 0);
  };

  const minWidth = `${calculateDynamicWidth(roundedAnimatedTries.get()) / 48}em`;

  return (
    <div className={clsx(s.root, className, s[variant])}>
      {variant === 'peach' && (
        <img className={s.img} src={peachIcon} alt="Coins" />
      )}
      {variant === 'jam' && <img className={s.img} src={jamIcon} alt="Coins" />}
      {variant === 'tomato' && (
        <img className={s.imgTomato} src={tomatoIcon} alt="Coins" />
      )}

      <m.div className={s.coins} style={{ minWidth }}>
        {roundedAnimatedTries}
      </m.div>
    </div>
  );
};
