import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';

import { configService } from './services';

export const setup = () => {
  if (configService.env === 'production') {
    const tagManagerArgs = {
      gtmId: 'GTM-KQ5PVNW4',
    };

    TagManager.initialize(tagManagerArgs);

    Sentry.init({
      dsn: configService.sentryDsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
      ignoreErrors: [/401 Unauthorized/, /Request failed with status code 401/],
      beforeSend(event) {
        const request = event.request;

        if (
          request &&
          'status_code' in request &&
          request.status_code === 401
        ) {
          return null;
        }

        const exceptions = event.exception?.values;

        if (
          exceptions &&
          exceptions.some((exc) => exc.value?.includes('401'))
        ) {
          return null;
        }

        return event;
      },
    });
  }
};
