import React from 'react';
import clsx from 'clsx';

import { HeroSection } from '../HeroSection';

import PreloaderImgUrl from '@media/slap2.png';

import heroImg from '@media/loader.png';

import styles from './Preloader.module.scss';

interface PreloaderProps {
  className?: string;
}

export const Preloader: React.FC<PreloaderProps> = ({ className }) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.content}>
        <HeroSection
          img={heroImg}
          imgMargin={'100px 0 9px 0'}
          imgHeight={200}
          imgWidth={'100%'}
        />
        <img
          className={styles.img}
          src={PreloaderImgUrl}
          alt="qr code"
          width={292}
        />
      </div>
      <p className={styles.text}>Cheeks in position, preparing to slap...</p>
    </div>
  );
};
