import { configService } from '@app/services';

import iconHome from '@app/assets/icons/nav/home.png';
import iconQuests from '@app/assets/icons/nav/quests.png';
import iconPlay from '@app/assets/icons/nav/play.png';
import iconInvite from '@app/assets/icons/nav/invite.png';
import iconShop from '@app/assets/icons/nav/shop.png';

import iconSettings from '@app/assets/icons/nav/settings.svg';
import iconSpin from '@app/assets/icons/nav/spin.png';
import iconBoost from '@app/assets/icons/nav/boost.png';
import iconChat from '@app/assets/icons/nav/chat.png';

export const BottomNavItem = [
  {
    label: 'Home',
    icon: iconHome,
    path: '/',
    marginTop: '16px',
  },
  {
    label: 'Quests',
    icon: iconQuests,
    path: '/quests',
    marginTop: '8px',
  },
  {
    label: 'Play',
    icon: iconPlay,
    path: '/play',
    marginTop: '4px',
  },
  {
    label: 'Invite',
    icon: iconInvite,
    path: '/friends',
    marginTop: '8px',
  },
  {
    label: 'Shop',
    icon: iconShop,
    path: '/shop',
    marginTop: '16px',
  },
];

export const SideNavItem = [
  {
    label: 'Settings',
    icon: iconSettings,
    path: '/settings',
    bgColor: '#322A25',
  },
  {
    label: 'Spin!',
    icon: iconSpin,
    link: configService.wormfareAppLink,
    bgColor: '#B8FF66',
  },
  {
    label: 'Boost',
    icon: iconBoost,
    path: '/boosters',
    bgColor: '#322A25',
  },
  {
    label: 'Chat',
    icon: iconChat,
    link: 'https://t.me/wormfarechat',
    bgColor: '#322A25',
  },
];
