import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { StrokeText } from '@app/ui-kit';

import styles from './Cooldown.module.scss';

interface CooldownProps {
  className?: string;
  date: number;
}

const calcTimeDelta = (date: number) => {
  if (date === 0) {
    return 0;
  }

  return Math.ceil((date - Date.now()) / 1000);
};

export const Cooldown: React.FC<CooldownProps> = ({ className, date }) => {
  const [cooldown, setCooldown] = useState(() => calcTimeDelta(date));

  useEffect(() => {
    const newTimeDelta = calcTimeDelta(date);

    setCooldown(newTimeDelta);
  }, [date]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCooldown((prevCooldown) => {
        if (prevCooldown === 0) {
          return 0;
        }

        return prevCooldown - 1;
      });
      // increase interval to 1050 to avoid flickering
    }, 1050);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className={clsx(styles.root, className)}>
      <StrokeText className={styles.number} text={cooldown.toString()} />
    </div>
  );
};
