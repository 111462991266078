import { Injectable } from '@common/di';

type AppEnv = 'local' | 'development' | 'production';

@Injectable()
export class ConfigService {
  public readonly apiTimeout: number = 30000;
  public readonly apiUrl: string = process.env.REACT_APP_API_URL as string;
  public readonly isDev: boolean = JSON.parse(
    process.env.REACT_APP_IS_DEV || 'false',
  );
  public readonly isLocal: boolean = JSON.parse(
    process.env.REACT_APP_IS_LOCAL || 'false',
  );
  public readonly accessToken: string = process.env
    .REACT_APP_ACCESS_TOKEN as string;
  public readonly initData: string = process.env.REACT_APP_INIT_DATA as string;
  public readonly botLink: string = process.env.REACT_APP_BOT_LINK as string;
  public readonly wormfareAppLink: string = process.env
    .REACT_APP_WORMFARE_APP_URL as string;
  public readonly env: AppEnv = process.env.REACT_APP_ENV as AppEnv;
  public readonly nfcQuestId: string =
    (process.env.REACT_APP_NFC_QUEST_ID as string) || '_NfcWormfareQuest';
  public readonly spinQuestId: string =
    (process.env.REACT_APP_QUEST_SPIN_WHEEL_ID as string) ||
    '_Slap quests-SpintheWheel';
  public readonly referralCode =
    parseInt(process.env.REACT_APP_NFC_REFERRAL_CODE as string) || 338155380;

  // Bot detection settings
  public readonly botDetectionMisclicksLimit =
    parseInt(
      process.env.REACT_APP_BOT_DETECTION_MISCLICKS_LIMIT as string,
      10,
    ) || 20;
  public readonly botDetectionMinInterval =
    parseInt(process.env.REACT_APP_BOT_DETECTION_MIN_INTERVAL as string) ||
    20 * 60 * 1000;
  public readonly botDetectionMaxInterval =
    parseInt(process.env.REACT_APP_BOT_DETECTION_MAX_INTERVAL as string) ||
    30 * 60 * 1000;

  public readonly dashboardUrl = process.env.REACT_APP_DASHBOARD_URL as string;

  public readonly sentryDsn = process.env.REACT_APP_SENTRY_DSN;
}
