import React from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AirdropWidget, PartnersWidget, AmaWidget } from '@components';

import styles from './GameWidgets.module.scss';

interface GameWidgetsProps {
  className?: string;
}

export const GameWidgets: React.FC<GameWidgetsProps> = ({ className }) => {
  return (
    <Swiper
      className={clsx(className)}
      slidesPerView="auto"
      spaceBetween={8}
      centeredSlides={false}
      style={{ paddingRight: '15%' }}
    >
      {/* <SwiperSlide>
        <AirdropWidget />
      </SwiperSlide> */}
      <SwiperSlide>
        <PartnersWidget />
      </SwiperSlide>
    </Swiper>
  );
};
