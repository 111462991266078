import React from 'react';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { notifyInfo } from '@app/ui-kit/ToastNotifications';
import { Button } from '@app/ui-kit';

import { configService } from '@app/services';
import { ReactComponent as CopyIcon } from '@icons/copy.svg';
import { ReactComponent as ShareIcon } from '@icons/share.svg';

import s from './ReferralLinkWidget.module.scss';

interface ReferralLinkWidgetProps {
  className?: string;
  userId?: number;
}

export const ReferralLinkWidget: React.FC<ReferralLinkWidgetProps> = ({
  className,
  userId,
}) => {
  const classNames = clsx(s.root, className);

  const handleCopyToClipboard = (label: string, text: string | number) => {
    copy(text.toString());
    notifyInfo(`${label} copied to clipboard!`, { autoClose: 1000 });
  };

  const handleInviteFriend = () => {
    const webApp = window.Telegram.WebApp;

    setTimeout(() => {
      webApp.close();
    }, 1);

    webApp.openTelegramLink(
      `https://t.me/share/url?text=🔥 Let's slap the Worm together! 👉 Proceed via the 👇 link below 👇 & receive 25k juicy tomatoes for your FIRST SLAP!💥
      &url=${configService.botLink}/start?startapp=ref_${userId}`,
    );
  };

  return (
    <div className={classNames}>
      <p className={s.title}>Your referral link</p>
      <div className={s.linkWrap}>
        <span className={s.link}>
          {`${configService.botLink}/start?startapp=ref_${userId}` || 'none'}
        </span>
      </div>
      <div className={s.btnWrap}>
        <Button
          className={s.btn}
          variant="tertiary"
          bgColor="#FFC166"
          size="medium"
          gap="2px"
          leftIcon={<CopyIcon width={16} height={16} />}
          onClick={() =>
            handleCopyToClipboard(
              'Copy link to clipboard',
              `🔥 Let's slap the Worm together! 👉 Proceed via the 👇 link below 👇 & receive 25k juicy tomatoes for your FIRST SLAP!💥
              ${configService.botLink}/start?startapp=ref_${userId}` || 'none',
            )
          }
        >
          Copy Link
        </Button>

        <Button
          className={s.btn}
          variant="tertiary"
          bgColor="#907968"
          size="small"
          gap="2px"
          leftIcon={<ShareIcon width={16} height={16} />}
          onClick={handleInviteFriend}
        >
          Share
        </Button>
      </div>
    </div>
  );
};
