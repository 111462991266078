import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import iconSkills from '@app/assets/media/skills/power.png';
import iconEquip from '@app/assets/media/equip-icon.png';
import { NavItem } from '@app/components/NavBars/NavItem';
import { Button } from '@app/ui-kit';

import s from './SlapVersusMenu.module.scss';

interface SlapVersusMenuProps {
  className?: string;
}

export const SlapVersusMenu: React.FC<SlapVersusMenuProps> = ({
  className,
}) => {
  const navigate = useNavigate();

  return (
    <div className={cn(s.root, className)}>
      <NavItem
        label={'Skills'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.img}
              src={iconSkills}
              width={40}
              height={40}
              alt="skills"
            />
          </div>
        }
        onClick={() => {
          // TODO
        }}
      />
      <Button
        onClick={() => navigate('/match-making')}
        className={s.button}
        size="large"
      >
        Play
      </Button>
      <NavItem
        label={'Equip'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.img}
              src={iconEquip}
              width={40}
              height={40}
              alt="skills"
            />
          </div>
        }
        onClick={() => {
          // TODO
        }}
      />
    </div>
  );
};
