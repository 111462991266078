import React, { useEffect } from 'react';
import clsx from 'clsx';

import { useServiceState } from '@app/common/state';
import { botHunterService, configService } from '@app/services';
import { Button } from '@app/ui-kit';

import tomatoIcon from '@media/tomato_coin.png';
import bannedImg from '@media/banned.png';

import s from './BotHunter.module.scss';

export const BotHunter: React.FC = () => {
  const { coords, misclicks, isBotDetected, isChecking } = useServiceState(
    botHunterService,
    ['coords', 'isChecking', 'misclicks', 'isBotDetected'],
  );

  const handleTap = () => {
    botHunterService.handleTapOnPeach();
  };

  const handleOutsideClick = () => {
    botHunterService.handleOutsideClick();
  };

  const handleRestartApp = () => {
    window.Telegram?.WebApp?.close();
  };

  return (
    <div
      className={clsx(s.root, { [s.enabled]: isChecking })}
      onClick={handleOutsideClick}
    >
      <div className={s.content}>
        {isBotDetected ? (
          <>
            <div>
              <img src={bannedImg} width={256} height={256} alt="banned img" />

              <p className={s.title}>
                <b>You're banned!</b>
                <p className={s.text}>
                  Think about what you did and slap yourself
                </p>
              </p>

              <p className={s.title}>Come back in 48 hours. Bye!</p>
              <Button
                variant="tertiary"
                bgColor="#B8FF66"
                className={s.restartbBtn}
                onClick={handleRestartApp}
              >
                Restart app
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className={s.title}>
              <p className={s.text}>
                <b>Tap the </b>tomato
              </p>
              <p className={s.text}>to prove you’re real</p>
            </p>
          </>
        )}
      </div>
      {coords && !isBotDetected && (
        <button
          className={s.btn}
          style={{ left: coords.x, top: coords.y }}
          onClick={handleTap}
        >
          <img src={tomatoIcon} alt="peach" width={95} height={99} />
        </button>
      )}
    </div>
  );
};
