import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { BackButton, Page, SoundBtn } from '@components';
import { Button } from '@app/ui-kit';

import { ReactComponent as CloseIcon } from '@icons/close.svg';
import { ReactComponent as ExternalLinkIcon } from '@icons/external-link.svg';
import { ReactComponent as CopyIcon } from '@icons/copy.svg';

import {
  cacheService,
  configService,
  soundService,
  userService,
} from '@app/services';
import { useServiceState } from '@app/common/state';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '@ui-kit/ToastNotifications';

import { shortAddress } from '@app/utils/shortAddress';
import { Dialog } from '@app/ui-kit/Dialog';

import s from './SettingsPage.module.scss';
import { options } from '@app/constants/options';

export const SettingsPage: React.FC = () => {
  const navigate = useNavigate();

  const { userProfile } = useServiceState(userService);
  const { muted } = useServiceState(soundService, ['muted']);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDisconnectWallet = async () => {
    try {
      notifyInfo('Disconnect wallet, please wait...');
      await userService.disconnectWallet();
      notifySuccess('Disconnect wallet successfully!');
    } catch (error) {
      notifyError((error as Error).message);
    } finally {
      setIsDialogOpen(false);
    }
  };

  const handleClearCache = () => {
    cacheService.clear();

    const url = new URL(window.location.href);

    url.searchParams.set('clearCache', Date.now().toString());
    window.location.href = url.href;
  };

  const handleToggleSound = () => {
    soundService.muteAllSound();
  };

  const handleCopyToClipboard = (label: string, text: string | number) => {
    copy(text.toString());
    notifyInfo(`${label} copied to clipboard!`, { autoClose: 1000 });
  };

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate('/')} />
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onClick={handleDisconnectWallet}
      >
        Are you sure you want to disconnect your wallet?
      </Dialog>
      <h1 className={s.title}>Settings</h1>

      <div className={s.content}>
        <div className={s.section}>
          <h2 className={s.sectionTitle}>Account</h2>
          <ul className={s.sectionList}>
            <li className={s.sectionItem}>
              <span>
                <span className={s.label}>Username:</span>{' '}
                {userProfile?.username || 'none'}
              </span>
              <button
                className={s.copyBtn}
                onClick={() =>
                  handleCopyToClipboard(
                    'Username',
                    userProfile?.username || 'none',
                  )
                }
              >
                <CopyIcon />
              </button>
            </li>
            <li className={s.sectionItem}>
              <span>
                <span className={s.label}>TelegramID:</span>{' '}
                {userProfile?.id || 'none'}
              </span>
              <button
                className={s.copyBtn}
                onClick={() =>
                  handleCopyToClipboard('TelegramID', userProfile?.id || 'none')
                }
              >
                <CopyIcon />
              </button>
            </li>
            {!userProfile?.walletAddress && (
              <li className={s.sectionItem}>
                <a
                  className={s.link}
                  target="_blank"
                  href={`${configService.dashboardUrl}/slap`}
                  rel="noreferrer"
                >
                  Connect your wallet to get more peaches <ExternalLinkIcon />
                </a>
              </li>
            )}
            {userProfile?.walletAddress && (
              <li className={s.sectionItem}>
                <span>
                  <span className={s.label}>Connected wallet:</span>{' '}
                  {shortAddress(userProfile.walletAddress)}
                </span>

                <button
                  className={s.copyBtn}
                  onClick={() =>
                    handleCopyToClipboard(
                      'Wallet address',
                      userProfile.walletAddress || 'none',
                    )
                  }
                >
                  <CopyIcon />
                </button>

                <button
                  className={s.disconnectBtn}
                  onClick={() => setIsDialogOpen(true)}
                >
                  <CloseIcon />
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className={s.section}>
          <h2 className={s.sectionTitle}>General</h2>
          <ul className={s.sectionList}>
            <li className={s.sectionItem}>
              <span className={s.label}>Sound: </span>
              <SoundBtn
                className={s.soundBtn}
                isSound={muted}
                onSoundless={handleToggleSound}
              />
            </li>
            <li className={s.sectionItem}>
              <span className={s.label}>Cache: </span>
              <Button
                variant="tertiary"
                size="small"
                bgColor="#B8FF66"
                onClick={handleClearCache}
              >
                Clear
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Page>
  );
};
