export enum VersusSkillType {
  power,
  reaction,
  resilience,
}

export interface VersusSkillItem {
  type: VersusSkillType;
  level: number;
}
