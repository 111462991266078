import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import { AuthService } from '../AuthService';
import { mapFriendRawToFriend } from './mappers/friend';
import type {
  UserProfile,
  Friend,
  FriendRaw,
  PaginatedResponse,
  Squad,
  Steack,
  MilestoneList,
  MilestoneRaw,
} from '@app/types';
import { CacheService } from '../CacheService';

@Injectable()
@MakeObservable
export class UserService {
  @observable
  public isAuth = false;

  @observable
  public isProfileLoading = false;

  @observable
  public isClaimMilestone = false;

  @observable
  public isClaimReferralsRewards = false;

  @observable
  public userProfile: UserProfile | null = null;

  @observable
  public friends: Friend[] = [];

  @observable
  public milestones: MilestoneRaw[] = [];

  @observable
  public referralsRewards: MilestoneList | null = null;

  @observable
  public steack: Steack | null = null;

  @observable
  public isFriendsLoading = false;

  @observable
  public squad: Squad | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly cacheService: CacheService,
  ) {
    this.isAuth = this.authService.isAuth();
  }

  public async login(initData: string, refUserId?: number): Promise<void> {
    const { accessToken } = await this.apiService.post<
      { accessToken: string },
      { initData: string; refUserId?: number }
    >('/auth/login', {
      initData,
      refUserId: refUserId || undefined,
    });

    this.authService.setCredentials(accessToken);
  }

  public async fetchProfile(): Promise<void> {
    this.isProfileLoading = true;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');

    const timestampDelta = Date.now() - this.userProfile.currentTimestamp;

    this.cacheService.set('timestampDelta', timestampDelta);

    this.isProfileLoading = false;
  }

  public async fetchFriends(): Promise<void> {
    const params = { limit: 100 };

    this.isFriendsLoading = true;

    const friendsRaw = await this.apiService.get<PaginatedResponse<FriendRaw>>(
      '/user/my-referrals',
      params,
    );
    const friends: Friend[] = friendsRaw.items.map((item) =>
      mapFriendRawToFriend(item),
    );

    this.isFriendsLoading = false;

    this.friends = friends;
  }

  public async joinSquad(id: number): Promise<void> {
    const body = { id };

    const userProfile = await this.apiService.post<UserProfile>(
      '/squad/join',
      body,
    );

    this.userProfile = userProfile;
  }

  public async leaveSquad(id: number): Promise<void> {
    const body = { id };

    const userProfile = await this.apiService.post<UserProfile>(
      '/squad/leave',
      body,
    );

    this.userProfile = userProfile;
    this.squad = null;
  }

  public async connectWallet(address: string, hash: string): Promise<void> {
    await this.apiService.post<{ address: string; hash: string }>(
      '/user/connect-wallet',
      {
        address,
        hash,
      },
    );
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  public async disconnectWallet(): Promise<void> {
    await this.apiService.post('/user/disconnect-wallet');
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }
  public async authSteack(): Promise<void> {
    const steack = await this.apiService.post<Steack>('/auth-streak');

    this.steack = steack;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  public async fetchReferralsRewards(): Promise<void> {
    const referralsRewards = await this.apiService.get<MilestoneList>(
      '/referral-claim/list',
    );

    const milestones = referralsRewards.rewardList;

    this.milestones = milestones;
    this.referralsRewards = referralsRewards;
  }

  public async claimMilestone(milestone: number): Promise<void> {
    this.isClaimMilestone = true;

    const referralsRewards = await this.apiService.post<MilestoneList>(
      '/referral-claim/claim-milestone',
      {
        milestone,
      },
    );

    this.referralsRewards = referralsRewards;
    await this.fetchReferralsRewards();
    this.isClaimMilestone = false;
  }

  public async claimReferralsRewards(): Promise<void> {
    this.isClaimReferralsRewards = true;

    const referralsRewards = await this.apiService.post<MilestoneList>(
      '/referral-claim/claim',
    );

    this.referralsRewards = referralsRewards;
    await this.fetchReferralsRewards();
    this.isClaimReferralsRewards = false;
  }
}
