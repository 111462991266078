import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import s from './TapReaction.module.scss';

interface TapReactionProps {
  className?: string;
  x: number;
  y: number;
  multiplier: number;
}

export const TapReaction: React.FC<TapReactionProps> = ({
  className,
  x,
  y,
  multiplier,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => setIsVisible(false), 3000);

    return () => clearTimeout(timer);
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={clsx(s.root, className)} style={{ top: y, left: x }}>
      <span className={s.number}>+{multiplier}</span>
    </div>
  );
};
