import React, { useEffect, useState } from 'react';
import { BottomSheet } from '@app/ui-kit';
import { BotRewardWidget } from '../BotRewardWidget';
import { useServiceState } from '@app/common/state';
import { gameService, userService } from '@app/services';
import botIcon from '@media/booster/bot.webp';

export const AutoBotBottomSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userProfile } = useServiceState(userService, ['userProfile']);

  useEffect(() => {
    if (
      userProfile?.autoBotEarnedScore &&
      userProfile?.autoBotEarnedScore > 0 &&
      userProfile?.autoBotEarnedScore !== 0
    ) {
      setIsOpen(true);
    }
  }, [userProfile?.autoBotEarnedScore]);

  const handleClose = () => {
    setIsOpen(false);
    gameService.claimBotReward();
  };

  return (
    <BottomSheet bgColor="#26201C" open={isOpen} onClose={handleClose}>
      <BotRewardWidget
        title="At Your Service"
        subtitle="Slapping while you're napping"
        img={botIcon}
        reward={userProfile?.autoBotEarnedScore || 0}
        onClaim={handleClose}
        buttonLable="Claim"
      />
    </BottomSheet>
  );
};
