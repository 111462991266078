import cn from 'classnames';

import iconPower from '@app/assets/media/skills/power.png';
import iconReaction from '@app/assets/media/skills/reaction.png';
import iconResilience from '@app/assets/media/skills/resilience.png';
import levelFrame from '@app/assets/media/skills/level-frame.png';
import { VersusSkillItem, VersusSkillType } from '@app/types';

import s from './VersusSkill.module.scss';

const skillIcons: Record<VersusSkillType, string> = {
  [VersusSkillType.power]: iconPower,
  [VersusSkillType.reaction]: iconReaction,
  [VersusSkillType.resilience]: iconResilience,
};

interface VersusSkillProps {
  data: VersusSkillItem;
  className?: string;
}

export const VersusSkill: React.FC<VersusSkillProps> = ({
  data,
  className,
}) => {
  const { type, level } = data;

  return (
    <div className={cn(s.root, className)}>
      <img
        src={skillIcons[type]}
        alt={type.toString()}
        className={s.skillIcon}
      />
      <div className={s.levelWrapper}>
        <img
          src={levelFrame}
          alt={`skill level: ${level}`}
          className={s.levelFrame}
        />
        <div className={s.levelValue}>{level}</div>
      </div>
    </div>
  );
};
