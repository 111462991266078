import React from 'react';
import clsx from 'clsx';

import { RewardItem } from '@components';
import { Button } from '@ui-kit';

import styles from './BotRewardWidget.module.scss';

interface BotRewardWidgetProps {
  className?: string;
  reward: number;
  isClaming?: boolean;
  img: string;
  icon?: string;
  title: string;
  subtitle: string;
  buttonLable: string;

  onClaim: () => void;
}

export const BotRewardWidget: React.FC<BotRewardWidgetProps> = ({
  className,
  reward,
  isClaming,
  img,
  icon,
  title,
  subtitle,
  buttonLable,
  onClaim,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <img src={img} className={styles.img} alt="Bot Icon" />

      <div className={styles.contentWrap}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>

      <RewardItem className={styles.reward} value={reward} icon={icon} />

      <Button
        onClick={onClaim}
        disabled={isClaming}
        className={styles.button}
        size="large"
      >
        {buttonLable}
      </Button>
    </div>
  );
};
