import React from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from '../Alert';
import { IconButton } from '../IconButton';

import closeIcon from '@icons/close.svg';

import s from './ToastNotifications.module.scss';

export const ToastNotifications: React.FC = () => {
  return (
    <ToastContainer
      className={s.toastContainer}
      limit={3}
      closeButton={
        <IconButton
          className={s.closeButton}
          variant="close"
          size="small"
          icon={<img src={closeIcon} alt="close" />}
        />
      }
    />
  );
};

const defaultOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false,
  className: s.customToast,
  bodyClassName: s.customBody,
  progressClassName: s.customProgress,
};

const customToast = (message: string) => <Alert alertMsg={message} />;

export const notifySuccess = (message: string, options?: ToastOptions) => {
  toast.success(<Alert variant="success" alertMsg={message} />, {
    ...defaultOptions,
    ...options,
  });
};

export const notifyError = (message: string, options?: ToastOptions) => {
  toast.error(<Alert variant="error" alertMsg={message} />, {
    ...defaultOptions,
    ...options,
  });
};

export const notifyInfo = (message: string, options?: ToastOptions) => {
  toast.info(<Alert variant="info" alertMsg={message} />, {
    ...defaultOptions,
    ...options,
  });
};
