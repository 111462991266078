import { useNavigate } from 'react-router-dom';

import {
  BadgePoints,
  HeroSection,
  Page,
  SlapVersusMenu,
  BackButton,
} from '@components';
import { gameService } from '@app/services';
import { useServiceState } from '@app/common/state';
import slapVersusImg from '@media/slap-versus.png';

import s from './SlapVersusPage.module.scss';

export const SlapVersusPage = () => {
  const navigate = useNavigate();
  const { score } = useServiceState(gameService, ['score']);
  const { cucumbers } = { cucumbers: 45098 };

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <div className={s.header}>
        <BadgePoints value={score} variant="header-tomato" />
        <BadgePoints value={cucumbers} variant="header-cucumber" />
      </div>
      <div>
        <HeroSection
          title="Slap Versus"
          desc="Match with random players and slap the hell out of them"
          btnLable="How to play"
          imageOnTop
          img={slapVersusImg}
          imgMargin="0 0 16px"
          imgHeight={172}
          imgWidth={'100%'}
          className={s.hero}
          onClick={() => {
            // TODO
          }}
        />
        <SlapVersusMenu />
      </div>
    </Page>
  );
};
