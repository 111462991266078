import React from 'react';
import clsx from 'clsx';

import { ProgressBar } from '../ProgressBar';

import styles from './SlapsIndicator.module.scss';

interface SlapsIndicatorProps {
  className?: string;
  slapsValue: number;
  slapsMax: number;
}

export const SlapsIndicator: React.FC<SlapsIndicatorProps> = ({
  className,
  slapsValue,
  slapsMax,
}) => {
  const classNames = clsx(styles.root, className);
  const formattedValue = slapsValue.toLocaleString('en-US');

  return (
    <div className={classNames}>
      <ProgressBar
        variant="vertical"
        fillBarColor="#ABED5E"
        barBgColor="#181411"
        currentNumber={slapsValue}
        maxNumber={slapsMax}
        minNumber={0}
      />
      <span className={styles.value}>{formattedValue}</span>
      <span className={styles.label}>Slaps</span>
    </div>
  );
};
