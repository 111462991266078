import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EmptyListState,
  QuestItem,
  Page,
  BackButton,
  BadgePoints,
  QuestPage,
  HeroSection,
} from '@components';
import { ListItems, Skeleton, BottomSheet } from '@ui-kit';

import { questService } from '@services';
import { useServiceState } from '@app/common/state';
import { Quest } from '@app/types';

import partnersQuestImg from '@media/completed-day-hero.png';
import heroBg from '@media/earn-hero-bg.png';
import defaultImgUrl from '@media/wormfare.webp';
import headWormImg from '@media/worm-head.png';

import s from './TomatoQuestsPage.module.scss';

export const TomatoQuestsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { quests, isQuestsLoading } = useServiceState(questService, [
    'quests',
    'isQuestsLoading',
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestId, setSelectedQuestId] = useState<Quest['id'] | null>(
    null,
  );

  const completedQuests = quests.filter(
    (q) => q.isCompleted && q.category === 'Partners' && !q.isRewardClaimed,
  );

  const partnerQuest = quests.filter((q) => {
    const completeTimes = q.completeTimes ?? 0;

    if (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) {
      return false;
    }

    return q.category === 'Partners' && !q.isRewardClaimed;
  });

  const socialQuest = quests.filter((q) => {
    const completeTimes = q.completeTimes ?? 0;

    if (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) {
      return false;
    }

    return q.category === 'Social' && !q.isRewardClaimed;
  });

  const inProgressQuests = quests.filter((q) => {
    const completeTimes = q.completeTimes ?? 0; // Default to 0 if undefined

    if (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) {
      return false;
    }

    return q.inProgress && !q.isCompleted;
  });

  const selectedQuest = useMemo(
    () => quests.find((q) => q.id === selectedQuestId),
    [quests, selectedQuestId],
  );

  useEffect(() => {
    questService.fetchQuests();
  }, []);

  useEffect(() => {
    if (params.questId) {
      setIsOpen(true);
      setSelectedQuestId(params.questId);
    }
  }, [params.questId]);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedQuestId(null);
  };

  const handleOpen = (quest: Quest) => {
    if (quest.isLocked) {
      return;
    }

    setIsOpen(true);
    setSelectedQuestId(quest.id);
  };

  const handleClimed = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 400);
  };

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <HeroSection
        title="Earn Tomatoes Before Anyone Else"
        desc="Complete quests and earn tomatoes"
        img={partnersQuestImg}
        imgMargin={'0 0 28px 0'}
        imgHeight={172}
        imgWidth={'100%'}
      />
      <div className={s.container}>
        {!!inProgressQuests.length && (
          <ListItems<Quest>
            key={'In progress'}
            isLoading={isQuestsLoading}
            skeleton={<Skeleton variant="item" />}
            className={s.listWrap}
            listClassName={s.list}
            title={'In progress'}
            emptyView={
              <EmptyListState variant="nothing-here" borderRadius="rounded" />
            }
            items={inProgressQuests}
            keyExtractor={({ id }) => id}
            renderItem={(quest) => (
              <QuestItem
                variant="tomato"
                type={quest.type}
                title={quest.title}
                desc={quest.subtitle}
                scoreAmount={quest.rewardAmount}
                icon={quest.icon || defaultImgUrl}
                isLocked={quest.isLocked}
                lockedMessage={quest.lockMessage}
                completeTimes={quest.completeTimes}
                maxCompleteTimes={quest.maxCompleteTimes}
                onClick={() => handleOpen(quest)}
              />
            )}
          />
        )}
        <ListItems<Quest>
          key={'Partners'}
          title={'Partners Quests'}
          isLoading={isQuestsLoading}
          skeleton={<Skeleton variant="item" />}
          className={s.listWrap}
          listClassName={s.list}
          itemQuantity={partnerQuest.length}
          itemCompleted={!completedQuests.length ? 0 : completedQuests.length}
          emptyView={
            <EmptyListState variant="nothing-here" borderRadius="rounded" />
          }
          items={partnerQuest.length > 0 ? partnerQuest : []}
          keyExtractor={({ id }) => id}
          renderItem={(quest) => (
            <QuestItem
              variant="tomato"
              type={quest.type}
              title={quest.title}
              desc={quest.subtitle}
              isCompleted={quest.isCompleted}
              scoreAmount={quest.rewardAmount}
              icon={quest.icon || defaultImgUrl}
              isLocked={quest.isLocked}
              lockedMessage={quest.lockMessage}
              completeTimes={quest.completeTimes}
              maxCompleteTimes={quest.maxCompleteTimes}
              onClick={() => handleOpen(quest)}
            />
          )}
        />

        {!!socialQuest.length && (
          <ListItems<Quest>
            key={'Social'}
            title={'Social Quests'}
            isLoading={isQuestsLoading}
            skeleton={<Skeleton variant="item" />}
            className={s.listWrap}
            listClassName={s.list}
            emptyView={
              <EmptyListState variant="nothing-here" borderRadius="rounded" />
            }
            items={socialQuest.length > 0 ? socialQuest : []}
            keyExtractor={({ id }) => id}
            renderItem={(quest) => (
              <QuestItem
                variant="tomato"
                type={quest.type}
                title={quest.title}
                desc={quest.subtitle}
                isCompleted={quest.isCompleted}
                scoreAmount={quest.rewardAmount}
                icon={quest.icon || defaultImgUrl}
                isLocked={quest.isLocked}
                lockedMessage={quest.lockMessage}
                completeTimes={quest.completeTimes}
                maxCompleteTimes={quest.maxCompleteTimes}
                onClick={() => handleOpen(quest)}
              />
            )}
          />
        )}
      </div>

      <BottomSheet open={isOpen} onClose={handleClose} bgColor="#26201C">
        {isOpen === true &&
          selectedQuest?.completeTimes === selectedQuest?.maxCompleteTimes && (
            <div className={s.questLate}>
              <img src={headWormImg} className={s.img} alt="Booster Icon" />
              <p className={s.questLateText}>Oops, you're late!</p>
            </div>
          )}
        {selectedQuest &&
          selectedQuest.maxCompleteTimes !== selectedQuest.completeTimes && (
            <QuestPage
              variant="tomato"
              className={s.questComplex}
              quest={selectedQuest}
              onClaimed={handleClimed}
            />
          )}
      </BottomSheet>
    </Page>
  );
};
