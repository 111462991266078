import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useServiceState } from '@app/common/state';
import { questService } from '@app/services';
import { Widget } from '@components/Widget';
import { BadgePoints } from '@components/BadgePoints';

import styles from './PartnersWidget.module.scss';

interface PartnersWidgetProps {
  className?: string;
}

export const PartnersWidget: React.FC<PartnersWidgetProps> = ({
  className,
}) => {
  const navigate = useNavigate();
  const { quests } = useServiceState(questService, [
    'quests',
    'isQuestsLoading',
  ]);

  const classNames = clsx(styles.root, className);

  const selectedQuest = quests.find((q) => {
    const completeTimes = q.completeTimes ?? 0;

    if (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) {
      return false;
    }

    return q.category === 'Partners' && !q.isCompleted;
  });

  const handleClick = () => {
    navigate('/quests');
  };

  return (
    <Widget
      className={classNames}
      icon={selectedQuest?.icon || ''}
      title="Partner Engagement"
      content={<BadgePoints value={selectedQuest?.rewardAmount || 0} />}
      onClick={handleClick}
    />
  );
};
