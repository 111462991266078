import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { gameService, questService, userService } from '@app/services';
import { useServiceState } from '@app/common/state';
import {
  Worm,
  UserStats,
  BottomNav,
  Page,
  FloppyFishBg,
  FloppyFish,
  BotHunter,
  BackButton,
  GameWidgets,
  JamFallBg,
  CoinsIndicator,
  NavBottomBar,
  SlapsIndicator,
} from '@components';

import { ReactComponent as SettingsIcon } from '@icons/settings.svg';

import { IconButton } from '@ui-kit';
import imgMsgUrl from '@media/msg.webp';

import s from './GamePage.module.scss';
import { NavSideBar } from '@app/components/NavBars';

interface GamePageProps {
  onClose: () => void;
}

export const GamePage: React.FC<GamePageProps> = ({ onClose }) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { score } = useServiceState(gameService, ['score']);
  const { quests } = useServiceState(questService, [
    'quests',
    'isQuestsLoading',
  ]);
  const { energy } = useServiceState(gameService, ['energy']);
  const availableToClick = Math.floor(energy);
  const navigate = useNavigate();
  const navigateToLeague = () => navigate('/league');

  const { coords, isTurboActivated } = useServiceState(gameService, [
    'coords',
    'isTurboActivated',
  ]);

  const handleActivate = () => {
    gameService.activateTurboMode();
  };

  useEffect(() => {
    questService.fetchQuests();
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      gameService.loop();
    }, 1000);

    gameService.loop();

    return () => {
      clearInterval(timerId);
      gameService.saveScore();
    };
  }, []);

  return (
    <Page className={s.root} isHaveSideNav>
      <BackButton onClick={onClose} />
      <SlapsIndicator
        className={s.slapsIndicator}
        slapsValue={availableToClick}
        slapsMax={userProfile?.energyMax || 0}
      />
      {isTurboActivated && <FloppyFishBg className={s.floppyBg} />}
      {coords && (
        <FloppyFish
          className={s.floppyFish}
          positionX={coords.x}
          positionY={coords.y}
          onClick={handleActivate}
        />
      )}
      <BotHunter />
      <div className={s.widgets}>
        <GameWidgets />
      </div>

      <div className={s.wormWrap}>
        <Worm className={s.worm} />
      </div>
      <div className={s.coinsIndicator}>
        <CoinsIndicator variant="tomato" value={score} />
      </div>
    </Page>
  );
};
