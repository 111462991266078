import { useEffect, useState } from 'react';
import { useServiceState } from '@app/common/state';
import {
  analitycsService,
  authService,
  configService,
  gameService,
  userService,
} from '@app/services';

export const useAuth = (refUserId?: number) => {
  const { isAuth } = useServiceState(userService);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initGame = async () => {
      try {
        await userService.fetchProfile();
        await gameService.initialize();

        analitycsService.trackEvent({
          name: 'slap_start_game',
          variables: {
            game_name: 'slap',
          },
        });

        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error && error.message.includes('401')) {
          authService.logout();
          window.location.reload();
        }
      }
    };

    if (!isAuth) {
      const initData = configService.isLocal
        ? configService.initData
        : window.Telegram?.WebApp?.initData;

      userService.login(initData, refUserId).then(initGame);

      return;
    }

    initGame();
  }, [isAuth, refUserId]);

  return { isLoading, isAuth };
};
