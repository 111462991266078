import { useNavigate } from 'react-router-dom';

import { HeroSection, Page } from '@components';
import { BackButton } from '@components';

import shopImg from '@media/shop.png';
import shopCardImg from '@media/shop-card.png';

import s from './ShopPage.module.scss';

export const ShopPage = () => {
  const navigate = useNavigate();

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <HeroSection
        title="Shop"
        desc="Get exclusive skins and gear to slap your opponents in style"
        coming="Coming soon..."
        img={shopImg}
        imgMargin={''}
        imgHeight={241}
        imgWidth={'100%'}
      />

      <img src={shopCardImg} className={s.imgCard} alt="quests" />
    </Page>
  );
};
