import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  EmptyListState,
  Page,
  BackButton,
  ReferralLinkWidget,
  InviteProgressWidget,
  RewardReferralWidget,
  FriendItem,
  HeroSection,
} from '@components';
import { ListItems, Skeleton } from '@ui-kit';

import { userService } from '@services';
import { useServiceState } from '@app/common/state';

import heroImg from '@media/completed-day-hero.png';

import s from './FriendsPage.module.scss';

export const FriendsPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    friends,
    isFriendsLoading,
    userProfile,
    milestones,
    referralsRewards,
    isClaimMilestone,
    isClaimReferralsRewards,
  } = useServiceState(userService);

  useEffect(() => {
    userService.fetchFriends();
    userService.fetchReferralsRewards();
  }, []);

  const pendingFriends = friends.filter((f) => f.bonus === 0);
  const availableMilestones = milestones.filter((m) => !m.isClaimed);
  const totalReward =
    (referralsRewards?.availableScoreFromDirectReferrals || 0) +
    (referralsRewards?.availableScoreFromIndirectReferrals || 0);

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <HeroSection
        title="Referral Program"
        desc="Invite frens and earn tomatoes"
        img={heroImg}
        imgMargin={'7px 0 28px 0'}
        imgHeight={172}
        imgWidth={'100%'}
      />
      <div className={s.container}>
        <ReferralLinkWidget userId={userProfile?.id} />
        <InviteProgressWidget
          className={s.progressWidget}
          numberOfFriends={referralsRewards?.progress || 0}
          maxNumberOfFriends={referralsRewards?.progressNext}
          reward={referralsRewards?.progressNextRewardAmount || 0}
          milestones={availableMilestones}
          pendingFriends={
            pendingFriends.length ? pendingFriends.length : undefined
          }
          isDisabledClaim={isClaimMilestone}
        />
        <RewardReferralWidget
          newReferrals={userProfile?.invitedActiveUsersCount || 0}
          newReferralsReward={referralsRewards?.inviteBonusAmount || 0}
          yourReferralsReward={
            referralsRewards?.claimedScoreFromDirectReferrals || 0
          }
          theirReferralsReward={
            referralsRewards?.claimedScoreFromIndirectReferrals || 0
          }
          totalReward={totalReward}
          isDisabledClaim={isClaimReferralsRewards}
        />

        <ListItems
          isLoading={isFriendsLoading}
          skeleton={<Skeleton variant="squadItem" />}
          className={s.listWrap}
          listClassName={s.list}
          title="Frends list"
          emptyView={<EmptyListState borderRadius="rounded" />}
          items={friends}
          keyExtractor={({ id }) => id.toString()}
          renderItem={(friend) => (
            <FriendItem
              key={friend.id}
              className={s.userCard}
              fullName={friend.name}
              bonus={friend.bonus}
              avatarUrl={friend.avatarUrl}
              isPending={friend.bonus === 0 ? true : false}
            />
          )}
        />
      </div>
    </Page>
  );
};
