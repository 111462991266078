import { DIContainer } from '@app/common/di';
import { useServiceState } from '@app/common/state';
import { AuthService } from '@app/services/AuthService';
import { ConfigService } from '@app/services/ConfigService';
import { GameService } from '@app/services/GameService/GameService';
import { UserService } from '@app/services/UserService';
import { useEffect } from 'react';

const configService = DIContainer.resolve(ConfigService);
const userService = DIContainer.resolve(UserService);
const gameService = DIContainer.resolve(GameService);

export const Test = () => {
  const { isAuth, userProfile, isProfileLoading } =
    useServiceState(userService);
  const { score, energy } = useServiceState(gameService, ['score', 'energy']);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (!isAuth) {
      userService.login(configService.initData);
    }

    userService.fetchProfile().then(() => {
      timerId = setInterval(() => {
        gameService.click();
      }, 200);
    });

    return () => {
      clearInterval(timerId);
    };
  }, [isAuth]);

  return (
    <div>
      <p>game score: {score}</p>
      <p>game energy: {Math.floor(energy)}</p>
    </div>
  );
};
