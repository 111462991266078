import React from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg';

import styles from './Widget.module.scss';

interface WidgetProps {
  className?: string;
  icon: string;
  title: string;
  content: React.ReactNode;
  onClick: () => void;
}

export const Widget: React.FC<WidgetProps> = ({
  className,
  icon,
  title,
  content,
  onClick,
}) => {
  return (
    <div className={clsx(styles.root, className)} onClick={onClick}>
      <img src={icon} alt="icon" className={styles.icon} />
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.content}>{content}</div>
      <ArrowRightIcon className={styles.arrow} />
    </div>
  );
};
