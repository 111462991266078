import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

import rhombusUrl from '@media/button/rhombus.png';
import rhombusGreenUrl from '@media/button/rhombus-green.png';
import rhombusGreyUrl from '@media/button/rhombus-grey.png';

import styles from './Button.module.scss';

type ButtonSize = 'small' | 'large' | 'medium';

interface CommonButtonProps {
  children?: React.ReactNode;
  className?: string;
  btnType?: 'button' | 'link';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'unfilled';
  primaryVariantColor?: 'green' | 'grey';
  bgColor?: string;
  gap?: string;
  size?: ButtonSize;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  indicator?: boolean;
}

type ButtonElementProps = CommonButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorElementProps = CommonButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = ButtonElementProps | AnchorElementProps;

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  btnType = 'button',
  variant = 'primary',
  primaryVariantColor = 'green',
  bgColor,
  size = 'small',
  gap,
  leftIcon,
  indicator,
  rightIcon,
  ...restProps
}) => {
  const customStyle = {
    '--bg-color': bgColor,
    '--gap': gap,
  } as React.CSSProperties;

  const getRhombusUrl = (): string => {
    if (variant === 'primary') {
      if (primaryVariantColor === 'green') {
        return rhombusGreenUrl;
      } else if (primaryVariantColor === 'grey') {
        return rhombusGreyUrl;
      }
    }

    return rhombusUrl;
  };

  const content = (
    <>
      {leftIcon && React.cloneElement(leftIcon, { className: styles.iconLeft })}
      {variant === 'primary' || variant === 'secondary' ? (
        <span className={styles.wrap}>
          <img
            className={styles.rhombus}
            src={getRhombusUrl()}
            alt="rhombus"
            width={28}
          />
          <span className={styles.content}>{children}</span>
          {indicator && <span className={styles.indicator}></span>}
        </span>
      ) : (
        <span className={styles.content}>{children}</span>
      )}
      {rightIcon &&
        React.cloneElement(rightIcon, { className: styles.iconRight })}
    </>
  );

  const classNames = clsx(
    styles.root,
    className,
    styles[variant],
    styles[primaryVariantColor],
    styles[`${variant}-${size}`],
    { [styles.disabled]: 'disabled' in restProps && restProps.disabled },
  );

  if (btnType === 'button') {
    const buttonProps =
      restProps as React.ButtonHTMLAttributes<HTMLButtonElement>;

    return (
      <button
        className={classNames}
        {...buttonProps}
        style={
          variant === 'tertiary' || variant === 'unfilled'
            ? customStyle
            : undefined
        }
      >
        {content}
      </button>
    );
  } else {
    const anchorProps =
      restProps as React.AnchorHTMLAttributes<HTMLAnchorElement>;

    return (
      <a
        className={classNames}
        {...anchorProps}
        style={
          variant === 'tertiary' || variant === 'unfilled'
            ? customStyle
            : undefined
        }
      >
        {content}
      </a>
    );
  }
};
