export const maskName = (name: string | null) => {
  if (name == null) {
    return;
  }

  const formattedName = name.startsWith('@') ? name : `@${name}`;
  let username = formattedName.split('@')[1];

  if (username.length > 15) {
    username = `${username.slice(0, 12)}...`;
  }

  if (username.length < 8) {
    return `@${username[0]}${'*'.repeat(username.length - 2)}${username[username.length - 1]}`;
  }

  const firstThree = username.slice(0, 3);
  const lastThree = username.slice(-3);
  const middleMasked = '*'.repeat(username.length - 6);

  return `@${firstThree}${middleMasked}${lastThree}`;
};
